/* PAGE REGISTER */

.regisBox {
    padding: 5px;
}

.pRegisPdpa {
    font-weight: 400;
    font-size: .9rem
}

.regisHeader {
    border: $line-gold;
    padding: 20px;
    text-align: center;
}

.regisLogo {
    margin: 10px;
    width: 15%;
    height: auto;
}

.regisForm {
    background-color: #F1F3F5;
    padding: 30px;
}

.regisReferralCodeText {
    padding-top: 10px;
    font-size: 12px;
    font-weight: 400;
}