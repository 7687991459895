@import 'utils', 'register', 'login', 'home', 'wallpaper', 'order', 'horacle', 'v4home', 'v4components';

/* TAGS */
// * {
//   -webkit-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }

body {
  font-family: 'IBM Plex Sans Thai', sans-serif;
  font-weight: 500;
  background-color: $color-cream;
  color: $color-brown-dark;
  background-image: url(../assets/images/om168-bg-2023.jpg);
  background-size: cover;
  background-attachment: fixed;
}

h1,
h2,
h3,
h4 {
  color: $color-brown-dark;
}

label {
  font-size: 16px;
  color: $color-brown-dark;
}

/* COMPONENTS */

.line-section {
  border: $line-gold;
}

.line-section-v4 {
  border: $line-gold-thin-v4;
}

.desc {
  font-weight: 400;
  font-size: 0.9rem;
}

.strong-red {
  color: $color-red;
  font-weight: 600;
}

.strong-green {
  color: $color-green;
  font-weight: 600;
}

.btn-primary,
.btn-primary:hover,
.btn-primary.disabled,
.btn-primary:disabled {
  color: $color-black;
  background-color: $color-gold;
  border-color: $color-gold;
}

.form-label {
  font-size: 16px;
  color: $color-brown-dark;
}

.form-control {
  border: $line-gold;
  border-radius: 30px;
  font-weight: 400;
}

.form-select {
  border: $line-gold;
  border-radius: 30px;
  font-weight: 400;
}

.navBar {
  font-size: 20px;
  font-weight: 400;
}

.accordion-item {
  border-radius: 30px !important;
}

.accordion-button {
  padding: 0.5rem 1rem 0.5rem 1rem;
  color: $color-brown-dark;
  background-color: $color-white;
  border: $line-gold;
  border-radius: 30px !important;
}

.accordion-button:not(.collapsed) {
  color: $color-brown-dark;
  background-color: $color-white;
}

.accordian-body {
  background-color: $color-white-alpha;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-left: $line-gold;
  border-right: $line-gold;
  border-bottom: $line-gold;
}