/* PAGE - HOME */
.section-v4 {
  margin-top: .5rem;
}

.proflie-information-box {
  padding: .5rem;
  // border: $line-gold-v4-classic;
  // border-radius: $v4-radius;
  // background-color: $color-white;
}

.header-line-v4 {
  padding-top: .3rem;
  padding-bottom: .3rem;
  display: flex;
  flex-direction: row;
}

.header-line-v4::before,
.header-line-v4::after {
  content: "";
  flex: 1 1;
  border-bottom: $line-gold-v4;
  margin: auto;
}

.header-line-v4-box {
  padding-left: .8rem;
  padding-right: .8rem;
  padding-top: .3rem;
  padding-bottom: .3rem;
  border: $line-gold-v4;
  border-radius: 30px;
  background-color: $color-gold-v4;
  color: $color-brown-dark;
  font-weight: 600;
  font-size: 1.2rem;
}

.v4-box-no-line {
  padding: 1rem;
  text-align: left;
}

.v4-box-line {
  padding: 1rem;
  text-align: left;
  border: $line-gold-v4-classic;
  border-radius: $v4-radius;
}

.box-gold-bg {
  background-color: $color-gold-v4;
}

.box-center {
  text-align: center;
}

.text-name-horo {
  color: $color-gold-dark;
  font-weight: 600;
  font-size: .9rem;
  margin-bottom: 0;
}

.text-detail-horo {
  margin-top: 0;
  font-size: 0.8rem;
  color: $color-black;
  font-weight: 400;
  margin-bottom: 0;
}