/* PAGE WALLPAPER */

.wallpaperBg {
    background-image: url(../assets/images/om168-bg-2023-2.jpg);
    min-width: 100%;
    min-height: 100%;
  }
  
  .wallpaperBgItem {
    background-image: url(../assets/images/om168-bg-2023-2.jpg);
    position: fixed;
    min-width: 100%;
    min-height: 100%;
  }
  
  
  .wallpaperThumbnail {
    display: block;
    width: 105%;
    border: 2px solid $color-gold;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .wallpaperThumbnailNotActive {
    opacity: 0.9;
    display: block;
    width: 105%;
    border: 2px solid #2d3436;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .wallpaperBox {
    padding: 20px;
  }
  
  .wallpaperContainer {
    position: relative;
    width: 33.33%;
    max-width: 300px;
    margin-bottom: 20px;
    /* padding-left: 3px;
    padding-right: 3px; */
  }
  
  .wallpaperImage {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .wallpaperNewIcon {
    position: absolute;
    top: 0px;
    width: 68px;
    height: 68px;
  }
  
  .wallpaperOverlay {
    border-radius: 6px;
    position: absolute;
    bottom: 2px;
    left: 0px;
    margin-left: 14px;
    margin-right: 8px;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.6);
    color: #f1f1f1;
    width: auto;
    transition: .5s ease;
    opacity: 1;
    color: white;
    font-size: 20px;
    padding: 10px;
    text-align: center;
    font-size: 12px;
  }
  
  .wallpaperDescriptionText {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 400;
    color: $color-brown-dark;
  }
  
  .wallpaperOrderHeaderText {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    color: $color-white;
  }
  
  .wallpaperShortDescription {
    margin-top: 20px;
    border-radius: 6px;
    border: 2px solid $color-gold;
    padding: 20px;
    background-color: $color-white-alpha;
    color: $color-brown-dark;
    font-size: 18px;
    font-weight: 400;
  }
  
  .wallpaperOrderSummaryHeader {
    color: $color-gold;
    font-size: 20px;
    font-weight: 400;
  }
  
  .wallpaperOrderSummary {
    margin-top: 20px;
    border-radius: 6px;
    border: 2px solid $color-gold;
    padding: 20px;
    background-color: #2d3436;
    color: $color-gold;
    font-size: 16px;
    font-weight: 400;
  }
  
  .wallpaperCategoryHeader {
    padding-top: 20px;
    color: $color-brown-dark;
    display: flex;
    flex-direction: row;
  }
  
  .wallpaperCategoryHeader::before,
  .wallpaperCategoryHeader::after {
    content: "";
    flex: 1 1;
    border-bottom: $line-gold-thin-v4;
    margin: auto;
  }