// Seven days bar
.sevendays-bar-box {
    margin: .3rem;
    text-align: center;
}

.seven-cols {
    padding: .3rem 0 .3rem 0;
    width: 14.285714285714285714285714285714%;
    border-radius: $v4-radius;
    // background-color: $color-white;
    // border: $line-gold-thin-v4;
}

.seven-cols-active {
    background-color: $color-gold-v4;
}

.sevendays-text-date {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
}

.sevendays-text-day {
    font-size: .7rem;
    font-weight: 400;
    margin: 0;
}

// Today Box
.today-date-6am {
    font-size: .8rem;
    font-weight: 400;
}

.today-box {
    padding: 0rem 1rem 0rem 1rem;
    margin-bottom: .5rem;
}

.today-box-inside {
    position: relative;
}

.today-noti-icon {
    color: $color-white;
    text-align: center;
    padding: 4px;
    position: absolute;
    top: -15px;
    right: -15px;
    height: 30px;
    width: 30px;
    background-color: $color-red-v4;
    border-radius: 50%;
    display: inline-block;
}

// Daily Colors
.daily-text {
    font-size: .7rem;
}

.daily-colors-meaning-red {
    color: $color-red;
    margin-bottom: .3rem;
}

.daily-colors-meaning {
    margin-bottom: .3rem;
}

.daily-colors-color {
    border-radius: $v4-radius;
    height: 80%;
    width: 100%;
}

// YAM
.yam-box {
    margin: 1rem .5rem 0 0;
    border-radius: $v4-radius;
    border: $line-gold-v4-border;
    padding: 1rem;
    position: relative;
}

.yam-icon {
    color: #f1c40f;
    text-align: center;
    padding: 4px;
    position: absolute;
    top: -15px;
    left: -10px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: inline-block;
}

.yam-line {
    padding: .1rem 0 .1rem 0;
    margin: .2rem 0 .2rem 0;
    border-radius: 30px;
}

.yam-1 {
    background-color: $color-green-yam;
}

.yam--1 {
    background-color: $color-red-yam;
    color: $color-white;
}

.yam-0 {
    background-color: $color-yellow-yam;
}

.yam-alert {
    border: $line-gold-v4-white;
    -webkit-animation: borderBlink 0.5s step-end infinite;
    animation: borderBlink 0.5s step-end infinite;
}

.iconSuggestedYam {
    -webkit-animation: iconBlink 0.5s step-end infinite;
    animation: iconBlink 0.5s step-end infinite;
}

@-webkit-keyframes iconBlink {

    from,
    to {
        color: transparent;
    }

    50% {
        color: $color-white;
    }
}

@keyframes iconBlink {

    from,
    to {
        color: transparent;
    }

    50% {
        color: $color-white;
    }
}

.planner-calendar {
    font-family: 'IBM Plex Sans Thai', sans-serif;
    border: $line-white;
    width: 100%;
    margin-top: 1rem;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.planner-day-best {
    font-weight: 700;
    color: #e67e22 !important;
    text-align: center;
    padding: 4px;
    height: 30px;
    width: 30px;
    background-color: $color-white;
    border: 2px solid #e67e22 !important;
    border-radius: 30px;
}

.planner-day-good {
    font-weight: 700;
    color: $color-green-yam !important;
    text-align: center;
    padding: 4px;
    height: 30px;
    width: 30px;
    background-color: $color-white;
    border: 2px solid $color-green-yam !important;
    border-radius: 30px;
}

.planner-day-bad {
    font-weight: 700;
    color: $color-red-yam !important;
    text-align: center;
    padding: 4px;
    height: 30px;
    width: 30px;
    background-color: $color-white;
    border: 2px solid $color-red-yam !important;
    border-radius: 30px;
}

// .react-calendar__tile {
//     color: $color-brown-dark;
// }

// .react-calendar__month-view__days__day--weekend {
//     color: $color-red-v4;
// }

.react-calendar__tile--now {
    font-weight: 700;
    color: $color-white;
    text-align: center;
    padding: 4px;
    height: 30px;
    width: 30px;
    background-color: $color-gold-v4;
    border-radius: 30px;
}

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover {
    font-weight: 600;
    color: $color-white;
    text-align: center;
    padding: 4px;
    height: 30px;
    width: 30px;
    background-color: $color-gold-v4-border;
    border-radius: 30px;
}

.calendar-legend {
    margin-top: 1rem;
}

.calendar-box {
    margin-top: 1rem;
    padding: 1rem;
    border: $line-gold-v4-border;
    border-radius: 20px;
    background-color: $color-white;
    font-size: .9rem;
}

.text-calendar-desc {
    padding: 0;
    font-weight: 500;
    font-size: .8rem;
}

.icon-today {
    color: $color-white;
    text-align: center;
    height: 15px;
    width: 15px;
    background-color: $color-gold-v4;
    border-radius: 50%;
}

.icon-best-day {
    color: $color-white;
    text-align: center;
    height: 15px;
    width: 15px;
    background-color: $color-white;
    border: 3px solid #e67e22;
    border-radius: 50%;
}


.icon-good-day {
    color: $color-white;
    text-align: center;
    height: 15px;
    width: 15px;
    background-color: $color-white;
    border: 3px solid $color-green-yam;
    border-radius: 50%;
}

.icon-bad-day {
    color: $color-white;
    text-align: center;
    height: 15px;
    width: 15px;
    background-color: $color-white;
    border: 3px solid $color-red-yam;
    border-radius: 50%;
}

.planner-buy-package {
    border: $line-gold-thin-v4;
    text-align: center;
    border-radius: 20px;
    background-color: $color-white;
    padding: 2rem;
    font-size: 2rem;
    margin-top: 1rem;
}

// .react-calendar__tile--active:hover {}

// Planet Card
.planet-card-box {
    // margin: .2rem 0 .2rem 0;
    margin-top: 1rem;
}

.planet-card-header {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: .2rem 0 .2rem 0;
    background-color: $color-gold-v4;
    text-align: center;
    font-size: 0.8rem;
}

.planet-card-detail {
    padding-top: .4rem;
    text-align: center;
    background-color: $color-white;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.planet-card-rasi-name {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0;
}

.planet-card-rasi-desc {
    text-align: center;
    font-size: .8rem;
    font-weight: 400;
}

.planet-card-wrap {
    text-align: center;
    margin-top: 1.5rem;
    position: relative;
}

.planet-card-line {
    padding: 0 15px;
    border-top: $line-gold-v4-classic;
    height: 1px;
}

.planet-card-dot {
    width: 30px;
    height: 30px;
    float: left;
    margin-right: 150px;
    position: absolute;
    top: -15px;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.planet-card-planet-motion {
    margin-top: 1.2rem;
    font-size: .8rem;
    font-weight: 400;
}

.planet-card-planet-desc {
    // margin-top: 1.2rem;
    margin-bottom: 1rem;
    font-size: .9rem;
    font-weight: 400;
    text-align: left;
}

// LUCK
.luck-number-box {
    background-color: $color-white;
    padding: 1rem;
    border-radius: 20px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.luck-number {
    border: $line-gold-thin-v4;
    text-align: center;
    font-size: 2rem;
}

.text-luck-desc {
    padding: 1rem;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
}

// SHOP
.text-package-desc {
    margin-top: .5rem;
    text-align: center;
}

// DUANG
.text-lukkana {
    color: $color-gold-dark;
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 0;
    text-align: center;
}

.text-monthly {
    margin-top: 1rem;
    border: $line-gold-thin-v4;
    border-radius: 20px;
    background-color: $color-white;
    color: $color-brown-dark;
    padding: 1rem;
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 0;
    text-align: center;
}