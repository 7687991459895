/* PAGE - LOGIN */

.loginBox {
    border: $line-gold;
    padding: 20px;
    text-align: center;
    vertical-align: middle;
  
    position: fixed;
    top: 0;
    left: 0;
  
    min-width: 100%;
    min-height: 100%;
  }
  
  .loginLogo {
    margin-top: 100px;
    margin-bottom: 50px;
    width: 40%;
    height: auto;
  }