.horacle-cards-area {
    margin: 20px;
}

.horacle-desc-area {
    margin: 25px;
    background-color: $color-white-alpha;
    padding: 10px;
    border-radius: 6px;
    border: $line-gold;
}


.horacle-card-box {
    padding: 5px;
}