/* COLORS*/
$color-purple: #8e44ad;
$color-gold: #e9d189;
$color-gold-dark: #c7ac78;

$color-white-alpha: rgba(255, 255, 255, 0.8);
$color-black: #2C2C2C;
$color-white: #ffffff;
$color-red: #ff0000;
$color-green: #008000;

$line-gold: 1px solid $color-gold;

// V4 COLORS
$color-cream: #f4f4f4;
$color-brown-dark: #423839;
$color-gold-v4: #ecd79f;
$color-gold-v4-border: #facb34;
$color-gold-v4-classic: #d7ae44;

$color-green-yam: #2ecc71;
$color-red-yam: #ea2027;
$color-yellow-yam: #fad390;

$color-red-v4: #e84118;
$color-purple-v4: #9b59b6;
$color-blue-v4: #3498db;

$v4-radius: 20px;

$line-gold-thin-v4: 1px solid $color-gold-v4-classic;
$line-white: 2px solid $color-white;
$line-gold-v4: 2px solid $color-gold-v4;
$line-gold-v4-border: 2px solid $color-gold-v4-border;
$line-gold-v4-classic: 2px solid $color-gold-v4-classic;
$line-gold-v4-white: 3px solid $color-cream;