/* PAGE - HOME */
$dis-logo: -30px;

.menubar {
  background-color: $color-white;
  padding: 0;
}

.menubar-text {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-top: 5px;
  color: $color-purple;
}

.logo-box {
  text-align: center;
}

.logo-om {
  position: relative;
}

.planet-position {
  color: $color-purple;
  font-weight: 600;
}

.yamGood {
  background-color: #DDFFD8;
  padding: 20px;
  border-radius: 6px;
  border: 3px solid #98D98E;
  margin-top: 20px;
  text-align: center;
}

.yamBad {
  background-color: #FFBDB4;
  padding: 20px;
  border-radius: 6px;
  border: 3px solid #FF6D59;
  margin-top: 20px;
  text-align: center;
}

.yamMid {
  background-color: #e8e4bb;
  padding: 20px;
  border-radius: 6px;
  border: 3px solid #f0de12;
  margin-top: 20px;
  text-align: center;
}

.duangBox {
  background-color: $color-white-alpha;
  padding: 20px;
  border-radius: 6px;
  border: $line-gold;
  margin-top: 20px;
  text-align: left;
}

.profileImage {
  width: 100%;
  border-radius: 50%;
  border: $line-gold;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.homeBox {
  padding: 20px;
  vertical-align: middle;
  min-width: 100%;
  min-height: 100%;
}

.homeMenu {
  margin-top: 0px;
  border: $line-gold;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.text-name {
  color: $color-gold-dark;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0;
}

.text-detail {
  margin-top: 0;
  font-size: 0.9rem;
  color: $color-black;
  font-weight: 400;
  margin-bottom: 0;
}

.text-package-left {
  font-size: 0.85rem;
  color: $color-brown-dark;
  font-weight: 500;
}

.jakka-box {
  position: relative;
  top: 0;
  left: 0;
  text-align: left;
}

.header-section {
  margin-top: 0;
  font-size: 1.3rem;
  color: $color-gold-dark;
  font-weight: 600;
}

.btn-card {
  width: 50%;
}

.btn-card-box {
  margin-top: 10px;
  text-align: center;
}